<template>
  <el-dialog custom-class="audit-logs-dialog-layout" :title="$tf('audit_logs_details')" @close="closeHandler" top="0" visible modal>
    <slot />
  </el-dialog>
</template>

<script>
export default {
  name: 'AuditLogsDetailsDialogLayout',
  props: {
    closeHandler: { type: Function, required: true }
  }
};
</script>

<style lang="stylus">
.audit-logs-dialog-layout {
  top: 50%;
  margin: 0 auto;
  transform: translateY(-50%);
  display: flex;
  max-height: 90vh;
  flex-flow: column;

  .el-dialog {
    &__header {
      flex: none;
    }

    &__body {
      flex: 1;
      overflow: auto;
    }
  }
}
</style>
