<template>
  <audit-logs-details-dialog-layout :close-handler="dispatchCloseEvent">
    <pre class="audit-logs-details-dialog__json" v-text="formattedItem"></pre>
  </audit-logs-details-dialog-layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import AuditLogsDetailsDialogLayout from './audit-logs-details-dialog-layout';

@Component({
  props: {
    item: { type: Object, required: true }
  },
  components: { AuditLogsDetailsDialogLayout }
})
export default class AuditLogsDetailsDialog extends Vue {
  get formattedItem() {
    return JSON.stringify(this.item, '\t', 2);
  }

  dispatchCloseEvent() {
    this.$emit('close');
  }
}
</script>

<style lang="stylus">
.audit-logs-details-dialog {
  &__json {
    margin: 0;
  }
}
</style>
